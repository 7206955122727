import { makeAutoObservable, runInAction } from 'mobx';
import { getApplicationsMappingMenu } from '../../data/company/repositories/companyApplicationMappingMenuRepository';

import {
    arrGetSubDashboardComponentV2,
    arrGetSubDashboardComponentV2GroupDetail
} from '../../models/dashboard/SubDashboardComponentV2';
import RootStore from '../Root.store';

class SubDashboardStore {
    private _subDashboardMenuList: arrGetSubDashboardComponentV2[] = [];
    private _selectedApplicationGroup = '';
    private _groupedApplications: {
        groupName: string;
        groupItems: arrGetSubDashboardComponentV2GroupDetail[];
    }[] = [];
    private _selectedCompanyToken = '';

    public get selectedCompanyToken() {
        return this._selectedCompanyToken;
    }

    public get subDashboardMenu() {
        return this._subDashboardMenuList;
    }

    public get selectedApplication() {
        return this._selectedApplicationGroup;
    }

    public get groupedApplications() {
        return this._groupedApplications;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public setSelectedApplication(
        applicationGroupName: string,
        companyToken: string
    ) {
        this._selectedApplicationGroup = applicationGroupName;
        this._selectedCompanyToken = companyToken;
    }

    public grouppingApplications(
        responseSubDashboardResult: arrGetSubDashboardComponentV2[]
    ) {
        // if (responseSubDashboardResult.length === 0) {
        //     this._groupedApplications = [
        //         {
        //             groupName: 'F1 ERP WEB SANDBOX',
        //             groupItems: []
        //         }
        //     ];

        //     return;
        // }

        const demoGroup: arrGetSubDashboardComponentV2GroupDetail[] = []
        const customersApplications: arrGetSubDashboardComponentV2GroupDetail[] = []
        const focusOneErpApplicationGroup: arrGetSubDashboardComponentV2GroupDetail[] = []
        const sandboxGroup: arrGetSubDashboardComponentV2GroupDetail[] = []
        const customersGuiApplications: arrGetSubDashboardComponentV2GroupDetail[] = []
        const demoGuiGroup: arrGetSubDashboardComponentV2GroupDetail[] = []
        const sandboxGuiGroup: arrGetSubDashboardComponentV2GroupDetail[] = []
        const customersForms: arrGetSubDashboardComponentV2GroupDetail[] = []

        responseSubDashboardResult.map(result => {
            // console.log(result)
            const demoPattern = 'D';
            const demoGuiPattern = 'H';
            const customersApplicationsPattern = 'C';
            const customersGuiApplicationsPattern = 'G';
            const focusOneErpApplicationPattern = 'A';
            const sandboxPattern = 'S';
            const sandboxGuiPattern = 'I';
            const customersFormPattern = 'F';

            demoGroup.push(...result.group_list.filter(g =>
                g.group_id.includes(demoPattern)
            ));

            customersApplications.push(...result.group_list.filter(g =>
                g.group_id.includes(customersApplicationsPattern)
            ));

            focusOneErpApplicationGroup.push(...result.group_list.filter(g =>
                g.group_id.includes(focusOneErpApplicationPattern)
            ));

            sandboxGroup.push(...result.group_list.filter(g =>
                g.group_id.includes(sandboxPattern)
            ));

            customersForms.push(...result.group_list.filter(g =>
                g.group_id.includes(customersFormPattern)
            ));

            if (result.type === 'gui' && this.rootStore.userCompanyStore.mode === 'gui') {
                //customersGuiApplications.push(...result.group_list);
                console.log(result.group_list)
                customersGuiApplications.push(...result.group_list.filter(g =>
                    g.group_id.includes(customersGuiApplicationsPattern)
                ));

                demoGuiGroup.push(...result.group_list.filter(g =>
                    g.group_id.includes(demoGuiPattern)
                ));

                sandboxGuiGroup.push(...result.group_list.filter(g =>
                    g.group_id.includes(sandboxGuiPattern)
                ));
            }
        });

        this._groupedApplications = [
            {
                groupName: 'F1 ERP WEB CUSTOMERS APPLICATIONS',
                groupItems: customersApplications
            },
            {
                groupName: 'F1 ERP GUI CUSTOMERS APPLICATIONS',
                groupItems: customersGuiApplications
            },
            {
                groupName: 'F1 ERP WEB DEMO',
                groupItems: demoGroup
            },
            {
                groupName: 'F1 ERP GUI DEMO',
                groupItems: demoGuiGroup
            },
            {
                groupName: 'F1 ERP WEB SANDBOX',
                groupItems: sandboxGroup
            },
            {
                groupName: 'F1 ERP GUI SANDBOX',
                groupItems: sandboxGuiGroup
            },
            {
                groupName: 'F1 ERP APPLICATIONS',
                groupItems: focusOneErpApplicationGroup
            },
            {
                groupName: 'F1 ERP FORM',
                groupItems: customersForms
            }
        ];
    }

    public async fetchSubDashboardMenu(citizenId: string) {
        const [error, responseSubDashboardList] =
            await getApplicationsMappingMenu({
                citizen_id: citizenId
            });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._subDashboardMenuList = responseSubDashboardList.result_list;
            this.grouppingApplications(
                responseSubDashboardList.result_list ?? []
            );
        });
    }
}

export default SubDashboardStore;
