import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';

import { Label, Button, Tabs, Input, Creatable } from 'common/materials';
import { usePagination } from 'common/hooks';
import { Pagination } from 'common/components';

import UserVerificationTable from './components/UserVerificationTable';
import UserVerificationInviteModal from './components/UserVerificationInviteModal';
import UserVerificationAddUsersModal from './components/UserVerificationAddUsersModal';
import UserVerificationViewModel from './UserVerification.viewmodel';

export type TabLabel = 'Pending' | 'Approved' | 'Rejected' | 'Unregistered';
const TAB_LABELS = ['Pending', 'Approved', 'Rejected', 'Unregistered'] as const;

function UserVerification() {
    const {
        isSCG,
        email,
        filteredUsers,
        isFetchingUserCompanyMapping,
        userRole,
        selectedCompany,
        companyOptions,
        activeTab,
        showModal,
        showModalInvite,
        isAdvanceSearch,
        searchUser,
        advanceSearchUser,
        onSelectCompany,
        setActiveTab,
        onClickEditUserStatusOnce,
        toggleShowModalInvite,
        setShowModal,
        exportExcel,
        setSearchUser,
        toggleIsAdvanceSearchUser,
        handleChangeAdvanceSearchUser,
        handleInviteSuccess,
        handleSubmitEditUser
    } = UserVerificationViewModel();

    const {
        collections: slicedFilterUsers,
        numberOfPages,
        currentPage,
        handleClickFirstPage,
        handleClickPreviousPage,
        handleClickNextPage,
        handleClickLastPage
    } = usePagination(filteredUsers, {
        initialPage: 1,
        pageSize: 50
    });

    const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        handleClickFirstPage();
        setSearchUser(event.target.value);
    }, []);

    const paginationJSX = (
        <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            handleClickFirstPage={handleClickFirstPage}
            handleClickNextPage={handleClickNextPage}
            handleClickPreviousPage={handleClickPreviousPage}
            handleClickLastPage={handleClickLastPage}
            className="ml-auto"
        />
    );

    return (
        <React.Fragment>
            <div className="flex flex-col gap-4">
                <p className="text-2xl font-semibold">User Verification</p>
                <div className="flex flex-col gap-3 bg-white px-6 py-4">
                    <div className="flex flex-col">
                        <Label>Company</Label>
                        <CreatableSelect
                            className="w-1/2"
                            isMulti
                            onChange={onSelectCompany}
                            options={companyOptions}
                            value={selectedCompany}
                            isDisabled={userRole === 'User'}
                        />
                    </div>
                    <div className="flex w-1/2 flex-row gap-4">
                        <UserVerificationAddUsersModal
                            setShowModal={setShowModal}
                            showModal={showModal}
                        />
                        <Button
                            size="sm"
                            outline
                            className="border-primary-900 text-primary-900"
                            onClick={exportExcel}
                            disabled={userRole === 'User'}
                        >
                            Export
                        </Button>
                        <Button
                            size="sm"
                            className="bg-primary-900 text-white"
                            onClick={toggleShowModalInvite}
                            disabled={userRole === 'User'}
                        >
                            {isSCG ? 'Add' : 'Invite'}
                        </Button>
                    </div>
                </div>
                <div className="bg-white p-4">
                    <p className="text-lg font-semibold">User Information</p>
                    <div className="flex gap-2 bg-white">
                        {isAdvanceSearch ? (
                            <Creatable
                                isMulti
                                isClearable
                                placeholder="Search user..."
                                options={advanceSearchUser}
                                value={advanceSearchUser}
                                onChange={handleChangeAdvanceSearchUser as any}
                                className="w-1/2"
                            />
                        ) : (
                            <Input
                                className="w-1/2"
                                type="text"
                                value={searchUser}
                                autoFocus={true}
                                placeholder="search users"
                                onChange={handleChangeSearch}
                            />
                        )}
                        <button
                            onClick={toggleIsAdvanceSearchUser}
                            className="rounded-md px-2 text-sm text-primary-900 outline-none transition-colors hover:bg-primary-50"
                        >
                            {isAdvanceSearch ? 'Multiple value search' : 'Single value search'}
                        </button>
                    </div>
                    <div className="ml-4 mt-4 flex flex-col md:flex-row md:items-baseline lg:flex-col xl:flex-row">
                        <Tabs
                            values={TAB_LABELS}
                            defaultSelectedValue={activeTab}
                            onChangeTab={setActiveTab}
                            className="max-w-[450px] flex-1"
                        />
                        {paginationJSX}
                    </div>

                    <UserVerificationTable
                        isFetching={isFetchingUserCompanyMapping}
                        activeTab={activeTab}
                        email={email}
                        users={userRole === 'User' ? [] : slicedFilterUsers}
                        onEditUserStatus={onClickEditUserStatusOnce}
                        onSubmitEditUser={handleSubmitEditUser}
                        onEditSuccess={handleInviteSuccess}
                        userdata={filteredUsers}
                    />

                    {paginationJSX}
                </div>
            </div>

            {/* INVITEMODAL */}
            <UserVerificationInviteModal
                isSCG={isSCG}
                isOpen={showModalInvite}
                onClose={toggleShowModalInvite}
                email={email}
                role={userRole}
                companyOptions={companyOptions as any} // For select company inside the invite modal
                onInviteSuccess={handleInviteSuccess}
            />
        </React.Fragment>
    );
}

export default observer(UserVerification);
